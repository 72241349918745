/* eslint-disable unicorn/consistent-function-scoping */

import { CustomerSelection } from '@desktop/components/customers/CustomerSelection';

import { useLog } from '@hooks/useLog';

import { ToastSeverityEnum, useToast } from '@hooks/useToast';

import { orderAnnotazioniAtom } from '@layouts/desktop/pages/private/orders/OrdersNew/atoms/OrderAnnotazioniAtom';
import { orderExtraAtom } from '@layouts/desktop/pages/private/orders/OrdersNew/atoms/OrderExtraAtom';

import { TabArticles } from '@layouts/desktop/pages/private/orders/OrdersNew/TabArticles';
import { TabBackorder } from '@layouts/desktop/pages/private/orders/OrdersNew/TabBackorder';
import { TabExtra } from '@layouts/desktop/pages/private/orders/OrdersNew/TabExtra';
import { TabNotes } from '@layouts/desktop/pages/private/orders/OrdersNew/TabNotes';
import { useForceRender } from '@libs/KzHooks/useForceRender';
import { useMount } from '@libs/KzHooks/useMount';
import { useSingleton } from '@libs/KzHooks/useSingleton';
import useTimeoutFn from '@libs/KzHooks/useTimeoutFn';
import { useUnmount } from '@libs/KzHooks/useUnmount';
import { useUpdateButSkipMount } from '@libs/KzHooks/UseUpdateButSkipMount';
import { Cart, CartRow } from '@models/Cart';
import { CustomerWithDetails } from '@models/CustomerWithDetails';
import { Recipient } from '@models/Recipient';

import { ArticleRow, CartService } from '@services/CartService';
import { OrdersService } from '@services/OrdersService';

import classNames from 'classnames';
import * as H from 'history';
import { useAtomValue } from 'jotai/utils';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { BaseSyntheticEvent, useRef } from 'react';

import { Prompt } from 'react-router-dom';
import { useImmer } from 'use-immer';

import './OrdersNew.scss';



export const OrdersNew: React.FC = () => {
	const log = useLog();

	const cartService = useSingleton<CartService>(() => new CartService(), []);
	const orderService = useSingleton<OrdersService>(() => new OrdersService(), []);

	const [ currentCart, setCurrentCart ] = useImmer<Cart | undefined>(undefined);
	const [ isDirtyCart, setDirtyCart ] = useImmer<boolean>(false);

	const ordersNotes = useAtomValue(orderAnnotazioniAtom);
	const ordersExtra = useAtomValue(orderExtraAtom);

	const [ isReady, clearTimeout, setDelayedUpdate ] = useTimeoutFn(() => forceUpdate(), 200);

	const forceUpdate = useForceRender();

	const { showToast } = useToast();


	useMount(() => {
		log.clear();
	});



	useUnmount(() => {
		clearTimeout();
	});


	const delayUpdate = () => {
		setDelayedUpdate();
	}



	const confirmOrder = async () => {
		try {
			if (currentCart != undefined) {
				await orderService.insertNewOrder(currentCart);

				// Appena inviato l'ordine non ha più senso tenere il carrello, così lo cancello.
				await cartService.emptyCart(currentCart);

				// E ne creo un altro nuovo
				const newEmptyCart = cartService.createCart();
				setCurrentCart(newEmptyCart);

				showToast(ToastSeverityEnum.SUCCESS, "Ordine inserito", "L'ordine è stato processato correttamente!");

				setDirtyCart(false);
				//forceUpdate();        // setCurrentCart fa già il rerender
			}
		}
		catch {
			showToast(ToastSeverityEnum.ERROR, "Errore", "Impossibile inviare l'ordine al momento");
		}
	}



	const saveOrder = async () => {
		try {
			if (currentCart != undefined) {
				await cartService.saveCart(currentCart);

				showToast(ToastSeverityEnum.INFO, "Carrello", "Carrello salvato temporaneamente!");

				setDirtyCart(false);
			}
		}
		catch {
			showToast(ToastSeverityEnum.ERROR, "Errore", "Impossibile salvare il carrello");
		}
	}



	const emptyCart = async () => {
		if (currentCart != undefined) {
			await cartService.emptyCart(currentCart);

			setCurrentCart(undefined);

			showToast(ToastSeverityEnum.INFO, 'Carrello annullato', 'Carrello annullato correttamente');

			setDirtyCart(false);
		}
	}



	//#region Eventi CardCustomer: (Selezione del cliente dal TreeSelect, format della label)
	const onSelectedCustomer = (customer: CustomerWithDetails, recipient: Recipient | undefined) => {
		const selectCustomer = async () => {
			let currentCart: Cart | undefined = await cartService.loadCart(customer.codice);
			if (currentCart == undefined) {
				currentCart = cartService.createCart();

				cartService.setCustomerInfo(currentCart, customer.codice, recipient?.codice);
				cartService.setNote(currentCart, "", "");
				cartService.setExtraInfo(currentCart, false, customer.indirizzo_e_mail_1, customer.deposito_predefinito);
			}

			setCurrentCart(currentCart);
		}

		void selectCustomer();
	};



	/**
	 * Crea la label della TreeSelect (Combobox) per la selezione del cliente
	 * @param {CustomerWithDetails} customer model Cliente
	 * @param {Recipient | null} recipient model Destinatario
	 * @returns {Promise<string>} label da usare
	 */
	const formatCustomerList = (customer: CustomerWithDetails, recipient: Recipient | undefined): string => {
		if (recipient)
			return `[${recipient.codice.toString().padStart(4, "0")}] ${recipient.ragione_sociale_1} (${recipient.localita})`;

		return `[${customer.codice.toString().padStart(4, "0")}] ${customer.ragione_sociale_1}`;
	}



	/**
	 * Classe CSS da usare per la singola entry della TreeSelect (se ha un carrello metto in grasseto, se ha insoluti metto in grigio, ecc)
	 * @param customer model Cliente
	 * @param recipient model Destinatario
	 * @returns {Promise<string | string>} css da associare all'entry
	 */
	const formatCustomerListStyle = async (customer: CustomerWithDetails, recipient: Recipient | undefined): Promise<string> => {
		const hasCart = await cartService.existsCart(customer.codice);

		const cssEntry = classNames({
			'customer-with-active-cart': hasCart,
			'disabled-customer': (customer.fido != 0)
		});

		return cssEntry;
	}

	//#endregion



	//#region Eventi TabNotes: Update delle note ordine
	useUpdateButSkipMount(() => {
		if (currentCart != undefined) {
			cartService.setNote(currentCart,ordersNotes.NoteCliente, ordersNotes.NoteAgente);
			//forceUpdate();      // Necessario, altrimenti se switcho tab e rientro, trigger di onMount() con i parametri per inizializzare l'atomState che sono vecchi e non aggiornati
			delayUpdate();
		}
	}, [ ordersNotes ]);
	//#endregion



	//#region Eventi TabExtra: Update indirizzo email, deposito, ecc
	useUpdateButSkipMount(() => {
		if (currentCart != undefined) {
			cartService.setExtraInfo(currentCart, ordersExtra.SendEmail, ordersExtra.EmailCliente ?? "", ordersExtra.Deposito);
			//forceUpdate();      // IDEM dovuto ad un globalstate (Atom) ed uno state non-React (currentCart / cartService) e le props che passo al componente
			delayUpdate();
		}
	}, [ ordersExtra ]);
	//#endregion



	//#region Eventi sulla tabella del carrello (onAddedProductsHandler, onUpdateCartRowHandler, onDeleteCartRowHandler)

	const onAddedProductsHandler = (products: ArticleRow[]) => {
		if (currentCart == undefined) {
			log.warn("Impossibile trovare il carrello");
		}
		else {
			for (const p of products) {
				cartService.addProduct(currentCart, p);
			}

			setDirtyCart(true);

			// Devo forzare un rerender del component, perchè aggiornando un object() Cart tramite delle chiamate interne e non dal functional component, React non sa che ci son state variazioni (infatti tramite un ipotetico rows.AddProduct() non è stato generato ne richiesto nessun refresh)
			//forceUpdate();
			delayUpdate();
		}
	}



	/**
	 * Aggiorno quantità/prezzo/sconto della riga sul carrello
	 * @param cr riga del carrello
	 * @param quantity quantità da modificare
	 * @param price prezzo impostato
	 * @param discount sconto impostato
	 */
	const onUpdateCartRowHandler = (cr: CartRow, quantity: number, price: number, discount: string): void => {
		if (currentCart != undefined) {
			cartService.updateProduct(currentCart, cr, quantity, price, discount);

			setDirtyCart(true);
			//forceUpdate();
			delayUpdate();
		}
	}



	/**
	 * Cancella una riga del carrello
	 * @param e
	 * @param cr riga da eliminare
	 */
	const onDeleteCartRowHandler = (e: HTMLElement, cr: CartRow): void => {
		confirmPopup({
			target: e,
			message: 'Vuoi eliminare questa riga?',
			icon: 'pi pi-exclamation-triangle',
			dismissable: true,
			accept: () => {
					if (currentCart != undefined) {
						cartService.removeProduct(currentCart, cr);

						setDirtyCart(true);
						//forceUpdate();
						delayUpdate();
					}
			}
		});
	}



	/**
	 * Aggiorna una riga marcandola special
	 * @param e
	 * @param cr riga carrello
	 */
	const onSetSpecialCartRowHandler = (e: HTMLElement, cr: CartRow): void => {
		if (currentCart != undefined) {
			cartService.setSpecialRow(currentCart, cr);

			setDirtyCart(true);
			//forceUpdate();
			delayUpdate();
		}
	}

	//#endregion



	//#region Button handlers (onConfirm, onSaveCart, onEmptyCart)
	const onConfirmOrderClick = (event: BaseSyntheticEvent) => {
		confirmDialog({
			header: 'Conferma invio ordine',
			message: 'Vuoi salvare l\'ordine?',
			icon: 'pi pi-exclamation-triangle',
			acceptClassName: 'p-button-success',
			accept: () => {
				void confirmOrder();
			},
			reject: () => { /* */ }
		});
	};



	const onSaveCartClick = (event: BaseSyntheticEvent) => {
		confirmDialog({
			header: 'Salvataggio temporaneo carrello',
			message: 'Sicuro di voler salvare l\'attuale carrello del cliente? (NON verrà confermato)',
			icon: 'pi pi-exclamation-triangle',
			acceptClassName: 'p-button-success',
			accept: () => {
				void saveOrder()
			},
			reject: () => { /* */ }
		});
	}



	const onEmptyCartClick = (event: BaseSyntheticEvent) => {
		confirmDialog({
			header: 'Cancellazione carrello',
			message: 'Sicuro di eliminare l\'attuale carrello del cliente?',
			icon: 'pi pi-exclamation-triangle',
			acceptClassName: 'p-button-danger',
			accept: () => {
				void emptyCart();
			},
			reject: () => { /* */ }
		});
	}
	//#endregion



	/**
	 * Mostro un messaggio quando viene cambiata pagina su un carrello "dirty"
	 * @param location nuovo URL di navigazione
	 * @returns {string} messaggio da visualizzare a popup
	 */
	const onPromptLeavingMessage = (location: H.Location) => {
		//  ${location.pathname}
		return `Il carrello NON è stato salvato. Sicuro di voler cambiare pagina?`;
	}



	return (
		<>
			<Prompt when={isDirtyCart} message={onPromptLeavingMessage} />
			<ConfirmDialog />
			<ConfirmPopup />

			<div className="content-section introduction">
				<h1>Crea ordine</h1>
				<p>Stai creando un nuovo ordine cliente</p>
			</div>

			<div className="content-section implementation">
				<div className="card">
					<CustomerSelection showOnlyActive={false} customerID={currentCart?.CustomerID} recipientID={currentCart?.RecipientID} onCustomerSelectedHandler={onSelectedCustomer} formatCustomer={formatCustomerList} formatCustomerStyle={formatCustomerListStyle} />
				</div>

				<div className="card">
					<TabView>
						<TabPanel header="Articoli">
							<TabArticles customerID={currentCart?.CustomerID ?? 0} cartRows={currentCart?.Rows} onAddedProductsHandler={onAddedProductsHandler} onUpdateCartRowHandler={onUpdateCartRowHandler} onDeleteCartRowHandler={onDeleteCartRowHandler} onSetSpecialCartRowHandler={onSetSpecialCartRowHandler} isCustomerSelected={currentCart != undefined && currentCart?.CustomerID != 0} />
						</TabPanel>

						<TabPanel header="Annotazioni">
							<TabNotes noteCliente={currentCart?.NoteCliente} noteAgente={currentCart?.NoteAgente} />
						</TabPanel>

						<TabPanel header="Extra">
							<TabExtra depositID={currentCart?.DepositID} sendEmail={currentCart?.isSendEmail} emailCustomer={currentCart?.EmailCustomer} />
						</TabPanel>

						<TabPanel header="Backorder">
							<TabBackorder customerID={currentCart?.CustomerID ?? 0} />
						</TabPanel>
					</TabView>
				</div>

				<div className="orders-new-button-bar">
					<Button label="Salva" iconPos="left" icon="pi pi-save" onClick={onSaveCartClick} disabled={currentCart == undefined || currentCart?.CustomerID == 0 || currentCart?.Rows.count() == 0} />
					<Button label="Svuota carrello" className="p-button-danger" iconPos="left" icon="pi pi-trash" onClick={onEmptyCartClick} disabled={currentCart == undefined || currentCart?.CustomerID == 0 || currentCart?.Rows.count() == 0} />
					<Button label="Concludi ordine" className="p-button-success" iconPos="left" icon="pi pi-check" onClick={onConfirmOrderClick} disabled={currentCart == undefined || currentCart?.CustomerID == 0 || currentCart?.Rows.count() == 0} />
				</div>
			</div>
		</>
	);
};
