/* eslint-disable react-refresh/only-export-components */

import { createContext, ReactNode, useState } from 'react';
import { useLog } from '@hooks/useLog';


type ThemeContextProps = {
    children: ReactNode
}

export type ThemeContextValue = {
    darkTheme: boolean;
}


const themeContextDefaultValues: ThemeContextValue = {
    darkTheme: true
};


export const ThemeContext = createContext<ThemeContextValue | undefined>(undefined);

export const ThemeProvider = (props: ThemeContextProps) => {
    const log = useLog();
    const [ contextState, setImmerState ] = useState<ThemeContextValue>(themeContextDefaultValues);

    return (
        <ThemeContext.Provider value={ contextState }>
            {props.children}
        </ThemeContext.Provider>
    );
};


/*
import { CurrencyContext, useCurrency } from './currency-context';
const Book = ({ item }) => {
    const currency = useCurrency();

    const price = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency.code,
    }).format(item.price * currency.conversionRate);

    return (
        <li>
            {item.title} - {price}
        </li>
    );
};







const AppContentProvider = ({ value, children }) => {
    return (
        <AppContentContext.Provider value={value}>
            {children}
        </AppContentContext.Provider>
    );
};
*/
