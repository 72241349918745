import { createJSONStorage } from 'zustand/middleware';

import { store } from '@davstack/store';



export const cartStore = store({
								   count  : 0,
								   name   : 'John',
								   age    : 25,
								   address: {
									   street: '123 Main St',
									   city  : 'Anytown',
								   },
							   },
							   {
								   name       : 'cartStore',
								   middlewares: [],
								   devtools   : {
									   enabled: true,
								   },
								   persist    : {
									   enabled: true,
									   name   : 'cart-storage',
									   storage: createJSONStorage(() => localStorage),
									   // Add any additional persist options here
								   },
							   })
	.computed((store) => ( {
		doubled  : {
			// optional input here
			read : () => store.count.use() * 2,
			write: (value: number) => store.count.set(value / 2),
		},
		fullName : {
			read : () => store.name.use() + ' Doe',
			write: (value: string) => store.name.set(value.split(' ')[0]),
		},
		fullName2: () => `${ store.name.use() } Doeaaa`,
	} ))
	.actions((store) => ( {
		increment() {
			store.count.set(store.count.get() + 1);
		},
		decrement() {
			store.count.set(store.count.get() - 1);
		},
	} ))
	.effects((store) => ( {
		logChanges: () => store.onChange(console.log),
	} ))
	.extend((store) => (
		{
			addTodo(text: string) {
				// .set method uses immer, so we can mutate the draft while keeping state immutable
				/*store.set((draft) => {
					draft.todos.push({
										 id: Date.now(),
										 text,
										 completed: false,
									 });
				});*/
			},
			toggleTodo(id: number) {
				/*store.set((draft) => {
					const todo = draft.todos.find((todo) => todo.id === id);
					if (todo) {
						todo.completed = !todo.completed;
					}
				});*/
			},
			deleteTodo(id: number) {
				/*store.set((draft) => {
					const index = draft.todos.findIndex((todo) => todo.id === id);
					if (index !== -1) {
						draft.todos.splice(index, 1);
					}
				});*/
			},
		} ));



/*
const customStorage: PersistStorage<{ todos: any[] }> = {
	getItem: (name) => {
		console.log(name, 'has been retrieved');
		// custom logic
		return "aaa"
	},
	setItem: (name, value) => {
		console.log(name, 'with value \n', value, '\nhas been saved');
		// custom logic
	},
	removeItem: (name) => {
		console.log(name, 'has been deleted');
		// custom logic
	},
};*/


/*
const activeTodo = cartStore.use((todos) => {
	return todos.find((todo) => todo.id === activeTodoId);
});
*/

const city = cartStore.address.city.get();
// const fullName = cartStore.fullName.get();
// const fullName2 = cartStore.fullName.use();


//const userAddress = cartStore.address.use();
//cartStore.address.street.set("pippo");
//cartStore.increment();
//cartStore.name.get();
/*cartStore.assign({
					 name: 'Jane',
					 age: 30,
				 });
*/
//cartStore.age.set((prevAge) => prevAge + 1);
/*
cartStore.set((draft) => {
	draft.name = 'Jane';
	draft.age = 30;
});
*/